<template>
  <b-row id="dashboard">
    <b-col>
      <b-card>
        <b-row>
          <b-col cols="12" class="d-flex flex-row-reverse">
            <b-button
              style="width: 7rem"
              class="mr-4"
              size="md"
              variant="primary"
              @click="handleExportDashboard()"
            >
              Exportar
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12 col-lg-2 col-sm-0"></b-col>
          <b-col class="col-12 col-lg-2">
            <b-form-group
              label="Categoria de produto"
              label-cols="0"
              label-size="lg"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                id="dashboard-category-filter-input"
                v-model="filters.category"
                @change="getDashboardData()"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-lg-2">
            <b-form-group
              label="Data de Início"
              label-size="lg"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="date"
                id="dashboard-start-date-filter-input"
                @change="dateChangeHandler($event, 'data_inicial')"
                @blur="dateBlurHandler($event, 'data_inicial')"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-lg-2">
            <b-form-group
              label="Data final"
              label-cols="0"
              label-size="lg"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="date"
                id="dashboard-end-date-filter-input"
                @change="dateChangeHandler($event, 'data_final')"
                @blur="dateBlurHandler($event, 'data_final')"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-lg-4">
            <b-form-group
              label="Buscar"
              label-cols="0"
              label-size="lg"
              label-class="font-weight-bold"
            >
              <b-form-input
                placeholder="Consultor"
                type="text"
                id="dashboard-search-filter-input"
                v-model="filters.consultor"
                @change="getDashboardData()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-col
          class="mb-3"
          cols="12"
          v-for="(item, index) in dashboardInformations"
          :key="index"
        >
          <h2>{{ item.titulo }}</h2>
          <div class="graphic-container">
            <DashboardGraphic :series="formatChartSeries(item.dados)" />
          </div>
        </b-col>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  exportDashboard,
  getDashboardData,
} from '../../services/requests/dashboard'
import DashboardGraphic from '../components/graphics/DashboardGraphic.vue'

export default {
  name: 'DashboardPage',
  components: { DashboardGraphic },
  data() {
    return {
      dashboardInformations: [],
      filters: {
        category: '',
        data_inicial: '',
        data_final: '',
        consultor: '',
      },
      document: null,
    }
  },
  computed: {
    today() {
      return new Date().toISOString().split('T')[0]
    },
  },
  mounted() {
    this.getDashboardData()
  },
  methods: {
    constraintDate(date) {
      const minDate = new Date(2021, 0, 1)
      const maxDate = new Date()
      const dateParts = date.split('-').map(Number)
      const parsedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])

      if (parsedDate < minDate) {
        return minDate.toISOString().split('T')[0]
      }

      if (parsedDate > maxDate) {
        return maxDate.toISOString().split('T')[0]
      }

      return date
    },
    dateChangeHandler(value, field) {
      this.filters[field] = this.constraintDate(value)

      this.getDashboardData()
    },
    dateBlurHandler(event, field) {
      event.target.value = this.filters[field]
    },
    getDashboardData() {
      getDashboardData(this.filters)
        .then((data) => {
          this.dashboardInformations = data.data.result.data
        })
        .catch(() => {
          console.log('Erro ao buscar dados.')
        })
    },
    formatChartSeries(data) {
      const formatedData = []
      data.map((year) => {
        formatedData.push({
          name: year.ano,
          data: year.meses.map((month) => {
            return month.valor
          }),
        })
      })
      return formatedData
    },
    handleExportDashboard() {
      exportDashboard(this.filters)
        .then((data) => {
          const href = URL.createObjectURL(data)

          const link = document.createElement('a')
          link.href = href
          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
        .catch(() => {
          console.log('Erro ao exportar arquivo.')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  color: #232138;
  font-weight: bold;
  font-size: 1rem;
}
.graphic-container {
  border: 1px solid #707070;
}
</style>
