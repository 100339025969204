module.exports = {
  MOTOR_INTERNO: 'motor-interno',
  DIAGNOSTICO: 'diagnostico',
  DIAGNOSTICO_DOCUMENTO: 'diagnostico-documento',
  DOWNLOAD: 'download',
  CONTINUAR_DIAGNOSTICO: 'continuar-diagnostico',
  DIAGNOSTICO_APOLICE: 'diagnostico-apolice',
  DEPENDENTES: 'dependentes',
  ESPECIALISTA: 'especialista',
  ESTAGIO_ESPECIALISTA: 'estagio-especialista',
  ESTAGIO: 'estagio',
  EMPRESA: 'empresa',
  FUNCIONARIO: 'funcionario',
  CLIENTE: 'cliente',
  CORRETORES: 'corretores',
  COMENTARIO: 'comentario',
  DIAGNOSTICO_COMENTARIO: 'diagnostico-comentario',
  DIAGNOSTICO_PRODUTO: 'diagnostico-produto',
  DIAGNOSTICO_PRODUTO_ITEM: 'diagnostico-produto-item',
  DIAGNOSTICO_PRODUTO_ITEM_OPCAO: 'diagnostico-produto-item-opcao',
  PRODUTO_ITEM_TABELA_ITEM: 'produto-item-tabela-item',
  POR_DIAGNOSTICO: 'por-diagnostico',
  POR_DIAGNOSTICO_ESPECIALISTA: 'lista-atividades-especialista',
  PAINEL_ATIVIDADES: 'painel-atividades',
  PAINEL_VENDAS: 'painel-vendas',
  LISTA_TAREFAS: 'lista-tarefas',
  SEGURO_ITEM: 'seguro-vida-item',
  PREVIDENCIA_FUNDO: 'previdencia-fundo',
  SEGURADORAS: 'seguradoras',
  BUSCA: 'busca',
  OPCOES: 'opcoes',
  UPLOAD: 'upload',
  DEPENDENT: 'dependente',
  DOCUMENTO: 'documento',
  UPLOAD_LOGO: 'upload-logo',
  UPLOAD_TERMS: 'upload-termos-uso-software',
  DOWNLOAD_TERMS: 'download-termos-uso-software',
  LOGADO: 'editar-logado',
  DASHBOARD: 'dashboard',
  DEV: 'dev',
  EXPORTAR: 'exportar',
  IMPORTAR: 'importar',
  PESSOA: 'pessoa',
  POR_PESSOA_ID: 'por-pessoa-id',
  PRODUTOS_SELECIONADOS: 'produtos-selecionados',
  APRESENTACAO_ESTUDO: 'apresentacao-estudo',
  UPDATE_STATUS: 'update-status',
}
